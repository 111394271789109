/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.20 : usayama) // Label 追加, ReactMarkdown 追加
 * @version 0.3 (2021.06.21 : usayama_dx) // リファクタリング
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// conf, action
import { APP_ROUTE } from '@config/routers'
import authActions from '@redux/auth/actions'
// components
import Alert from '@components/UIKit/Alert'
import LinkButton from '@components/UIKit/LinkButton'
// containers
import RegisterFormContainer from '@containers/RegisterFormContainer'
// helper
import { isEmpty } from '@lib/helpers'
// style, img, elm
// const
const { initVerifyRegisterUrl, verifyRegisterUrl } = authActions

/** 
 * export : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function RegisterContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   * @version 0.2 (2021.05.19 : usayama) // name, password 追加, setError 追加
   * @version 0.3 (2021.05.20 : usayama) // agreement, Terms 追加
   * @version 0.4 (2021.06.21 : usayama_dx) // リファクタリング
   */
  const { verify_url } = props
  const dispatch = useDispatch()
  const { verifyRegisterUrlResultStatus } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.17 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initVerifyRegisterUrl())
      if(verify_url) {
        dispatch(verifyRegisterUrl({ 'verify_url': verify_url }))
      }
      setInitPage(true)
    }
  }, [initPage, dispatch, verify_url])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.05.19 : usayama) // name, password 追加, VALIDATION_PATTERNS 導入
   * @version 0.3 (2021.05.20 : usayama_dx) // 規約同意文追加, ReactModal 追加
   * @version 0.4 (2021.06.21 : usayama_dx) // リファクタリング
   */
  return (
    <div>
      { 
        (isEmpty(verify_url) || verifyRegisterUrlResultStatus === 'error' ) && 
          <div>
            <Alert status="error" label={"message.error.verify_url"} isOpen={true} closeBt={false} />
            <LinkButton className="mb-1 text-center" to={APP_ROUTE.signup} label="text.to_signup" /> 
          </div>
      }
      { 
        (!isEmpty(verify_url) && verifyRegisterUrlResultStatus === 'success' ) && 
          <RegisterFormContainer verify_url={verify_url} />
      }
    </div>
  )
}
