/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.20 : usayama) // Label 追加, ReactMarkdown 追加
 * @version 0.3 (2021.06.21 : usayama) // 実装調整
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoPersonOutline, IoKeyOutline } from 'react-icons/io5'
import { useForm } from 'react-hook-form'
// conf, action
import { APP_ROUTE } from '@config/routers'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
import authActions from '@redux/auth/actions'
import termsActions from '@redux/terms/actions'
import modalActions from '@redux/modal/actions'
// components
import ModalLoading from '@components/UIKit/ModalLoading'
import InputGroup from '@components/Form/InputGroup'
import InputCheckbox from '@components/Form/InputCheckbox'
import Alert from '@components/UIKit/Alert'
import Button from '@components/UIKit/Button'
import LinkButton from '@components/UIKit/LinkButton'
import Modal from '@components/UIKit/Modal'
import Label from '@components/Typo/Label'
// containers
// helper
// style, img, elm
// const
const { initRegisterAccount, registerAccount } = authActions
const { getTerms } = termsActions
const { initModal, openModal } = modalActions

/** 
 * export : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function RegisterFormContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   * @version 0.2 (2021.05.19 : usayama) // name, password 追加, setError 追加
   * @version 0.3 (2021.05.20 : usayama) // agreement, Terms 追加
   * @version 0.4 (2021.06.21 : usayama) // watch 追加
   */
  const { handleSubmit, 
          register, 
          formState,
          setError,
          watch,
          errors } = useForm({ mode: 'onChange' })
  const { verify_url } = props
  const dispatch = useDispatch()
  const { loadRegisterAccount, registerAccountResultStatus } = useSelector(state => state.Auth)
  const { terms } = useSelector(state => state.Terms)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   * @version 0.2 (2021.05.18 : usayama) // initSignUp 追加
   * @version 0.3 (2021.06.21 : usayama) // initRegisterAccount に変更
   */
  const handleOnSubmit = (d) => {
    dispatch(registerAccount({'verify_url' : verify_url, ...d}))
  }

  /** 
   * event : 
   * 
   * @version 0.1 (2021.05.20 : usayama)
   */
  const handleOnModalOpen = () => dispatch(openModal())

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.05.19 : usayama)
   * @version 0.2 (2021.06.21 : usayama) // Register に変更
   */
  React.useEffect(() => {
    if(registerAccount && registerAccount.errors) {
      Object.keys(registerAccount.errors).map((key) => setError(key, { type: "manual", message: registerAccount.errors[key] }))
    }
  }, [setError])

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.17 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initRegisterAccount())
      dispatch(initModal())
      dispatch(getTerms())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.05.19 : usayama) // name, password 追加, VALIDATION_PATTERNS 導入
   * @version 0.3 (2021.05.20 : usayama_dx) // 規約同意文追加, ReactModal 追加
   * @version 0.4 (2021.06.28 : usayama_dx) // agreement が一部 password になっていたので修正
   */
  return (
    <div>
      <Modal bgColor="rgb(0,0,0,0.8)">
        <div className="container bg-white w-100 p-4 overflow-auto">
          { terms  && <div dangerouslySetInnerHTML={{ __html: terms.text}} /> }
        </div>
      </Modal>
      <ModalLoading isLoading={loadRegisterAccount} />
      <p className="mb-3"><Label label="text.register" /></p>
      {
        registerAccountResultStatus && 
        <Alert 
          status={registerAccountResultStatus} 
          label={"message." + registerAccountResultStatus + ".register"} 
          isOpen={true} 
          closeBt={true}
        />
      }
      {
        registerAccountResultStatus === 'success'
        ? <div className="text-center mt-3 mb-3"><LinkButton to={APP_ROUTE.root} label="text.to_home" /></div>
        : <form onSubmit={handleSubmit(handleOnSubmit)}>
            <InputGroup
              // id="isFocus"
              label={<IoPersonOutline size={25} />}
              placeholder="placeholder.name"
              name="name"
              feedback={errors && errors.name}
              errors={errors && errors.name}
              className={errors.name && "is-invalid"}
              inputClassName={errors.name && "is-invalid"}
              maxLength={255}
              inputRef={register({ required: true, maxLength: 255 })}
             />
            <InputGroup
              label={<IoKeyOutline size={25} />}
              placeholder="placeholder.password"
              type="password"
              name="password"
              feedback={errors && errors.password}
              errors={errors && errors.password}
              className={errors.password && "is-invalid"}
              inputClassName={errors.password && "is-invalid"}
              maxLength={255}
              inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.password })}
             />
            <InputGroup
              label={<IoKeyOutline size={25} />}
              placeholder="placeholder.password_confirm"
              type="password"
              name="password_confirm"
              feedback={errors && errors.password_confirm}
              errors={errors && errors.password_confirm}
              className={errors.password_confirm && "is-invalid"}
              inputClassName={errors.password_confirm && "is-invalid"}
              maxLength={255}
              inputRef={register({ 
                required: true, 
                pattern: VALIDATION_PATTERNS.password,
                validate: value => value === watch('password')
              })}
             />
            <div className="form-text mt-4">
              <p className="a-link a-link-dot" onClick={handleOnModalOpen} onKeyPress={handleOnModalOpen}><Label label="text.agreement" /></p>
            </div>
            <InputCheckbox 
              id="agreement"
              name="agreement"
              value="agree"
              label="label.agreement"
              inputWrapClasseName="justify-content-center"
              feedback={errors && errors.agreement}
              errors={errors && errors.agreement}
              className={errors.agreement && "is-invalid"}
              inputClassName={errors.agreement && "is-invalid"}
              inputRef={register({ required: true })}
            />
            <div className="form-action">
              <Button type="submit" color="success" label="register" disabled={!formState.isValid} />
            </div>
          </form>
      }
    </div>
  )
}
